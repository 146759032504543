import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UIDialogDelete',{attrs:{"deleting-name":_vm.deletingMenuTitle,"delete-callback":_vm.doDeleteOnDialog},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}),_c('p',{staticClass:"drawer-category-name"},[_vm._v("メニュー管理")]),_c(VCard,{staticClass:"pa-5"},[_c('ul-content-header',{attrs:{"heading-main":_vm.headingMain,"heading-sub":_vm.headingSub}}),_c(VRow,[_c(VCol,[_c('ul-breadcrumbs',{attrs:{"items":_vm.breadCrumbs}})],1)],1),_c(VCard,{staticClass:"mt-1 mb-1 pa-1 grey lighten-2 caption font-weight-bold",attrs:{"outlined":"","tile":""}},[_vm._v(" 登録できるメニューは最大30件です。 ")]),_c(VRow,[_c(VCol,[_vm._v(_vm._s(_vm.shopId)+"："+_vm._s(_vm.shopName)),_c('br'),_vm._v("カテゴリータイトル ："+_vm._s(_vm.category_Title))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12","lg":"6","md":"6","sm":"6"}},[_c(VBtn,{attrs:{"depressed":"","rounded":"","outlined":"","disabled":!_vm.writeFlg,"color":"primary"},on:{"click":_vm.goToNew}},[_vm._v("メニュー新規登録")]),_vm._v("  "),_c(VBtn,{attrs:{"depressed":"","rounded":"","outlined":"","color":"primary"},on:{"click":_vm.backToCategoryList}},[_vm._v("戻る")])],1)],1),_c('UIDataTable',{attrs:{"hide-default-footer":true,"headers":_vm.tableHeaders,"items":_vm.tableItems,"total-count":_vm.totalCount,"loading":_vm.isLoading,"table-options":_vm.tableOptions,"table-change-callback":_vm.tableChangeCallback,"edit-click-callback":_vm.editClickCallback,"delete-click-callback":_vm.deleteClickCallback},scopedSlots:_vm._u([{key:"custom",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{staticClass:"text-center"},[(item.image)?_c('img',{staticStyle:{"max-width":"50px","max-height":"50px","vertical-align":"bottom"},attrs:{"src":item.image,"alt":"メニュー画像"}}):_c('img',{staticStyle:{"max-width":"50px","max-height":"50px","vertical-align":"bottom"},attrs:{"src":require("../../assets/noimg@2x.png"),"alt":"メニュー画像"}})])],1)]}}])}),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"id":"web-preview","depressed":"","rounded":"","outlined":"","color":"primary","disabled":_vm.agreements.web === false},on:{"click":_vm.webPreview}},[_vm._v("Webプレビュー")]),_c(VBtn,{attrs:{"depressed":"","rounded":"","outlined":"","color":"primary","disabled":!(this.agreements.ap === true || this.isNotAgreement() === true)},on:{"click":_vm.preview}},[_vm._v("APプレビュー")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }