import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as MenuAPI from "@/api/menu";
import { CommonResponse, isSuccess } from "@/api/response";
import {
  DeleteRequest,
  SortRequest,
  RegisterRequest
} from "@/api/menu/request";

const MODULE_NAME = "menu/modify";

/**
 * メニュー情報削除/登録更新APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async deleteOne(deleteRequest: DeleteRequest) {
    const commonResponse = await MenuAPI.deleteOne(deleteRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async sort(sortRequest: SortRequest) {
    const commonResponse = await MenuAPI.sort(sortRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const commonResponse = await MenuAPI.register(registerRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Modify);
