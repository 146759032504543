import { Vue } from "vue-property-decorator";
import TalkSettingRegister from "../store/talk-setting/register";

/**
 * window.openのutilクラス
 */
export default class WindowOpen {
  /**
   * プレビュー画面を開きます。
   *
   * @param previewUrl プレビューのURL
   */
  static preview(previewUrl: string) {
    if (process.env.VUE_APP_API_BASE_URL.match('8080')) {
      //開発用
      window.open('http://localhost:9003' + previewUrl, "preview", "width=375,height=667");
    } else {
      //20210127 NEW_DEV-743 cyber 段 start
      if(previewUrl.includes("/sp/talk/talk")){
        window.open(previewUrl, "preview", "width=390,height=667");
      } else {
        window.open(previewUrl, "preview", "width=375,height=667");
      }
      //20210127 NEW_DEV-743 cyber 段 end
    }
  }

  /**
   * Webプレビュー画面を開きます。
   *
   * @param previewUrl プレビューのURL
   */
  static webPreview(previewUrl: string) {
    if (process.env.VUE_APP_API_BASE_URL.match('8080')) {
      //開発用
      window.open('http://localhost:9003' + previewUrl, "web-preview", "width=1500,height=820");
    } else {
      window.open(previewUrl, "web-preview", "width=1500,height=820");
    }
  }

  /**
   * urlを別のタブで開きます
   *
   * @param url URL
   */
  static tab(url: string) {
    window.open(url, "_blank");
  }
}
