import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { DeleteRequest, SearchRequest, SortRequest } from "@/api/menu/request";
import { SearchItem } from "@/api/menu/response";
import MenuSearch from "@/store/menu/search";
import MenuModify from "@/store/menu/modify";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import Sortable, { MoveEvent } from "sortablejs";
import WindowOpen from "@/utils/window-open";
import { GetRequest } from "@/api/shop-agreement/request";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import Admin from "@/store/admin/admin";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class MenuList extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "メニュー管理";
  headingSub = "Menu management";
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "", always: true, value: "reorder", sortable: false },
    { text: "画像", value: "custom", sortable: false },
    { text: "タイトル", value: "title", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "公開フラグ", value: "viewFlg", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // ------------
  // 変動値
  // ------------

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるメニュータイトル
  deletingMenuTitle = "";

  // 削除されるメニューID
  deletingMenuId = "";

  // ローディングステータス
  isLoading = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return MenuSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return MenuSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return MenuSearch.getTotalCount;
  }

  get breadCrumbs() {
    return [
      { text: "メニュー管理", disabled: true },
      {
        text: "店舗一覧",
        disabled: false,
        to: { name: "menu-management" }
      },
      {
        text: "メニューカテゴリ一覧",
        disabled: false,
        to: {
          name: "menu-management-category",
          params: { shopId: this.shopId },
          query: {
            shopName: this.shopName,
            category_Title: this.category_Title
          }
        }
      },
      { text: "メニュー一覧", disabled: true }
    ];
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータからカテゴリー名を返却する（必須）
   */
  get category_Title() {
    if (this.$route.query.category_Title) {
      return this.$route.query.category_Title;
    } else {
      return "";
    }
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    // 契約情報を取得する
    if (this.shopId) {
      let requestAgrement = {
        shopId: String(this.shopId)
      } as GetRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
      }
    } else {
      // スーパーユーザの場合は全て契約
      if (Admin.isAdmin) {
        this.agreements.ap = true;
        this.agreements.line = true;
        this.agreements.web = true;
      }
    }

    await MenuSearch.restore({
      shopId: this.shopId,
      categoryId: Number(this.id)
    } as SearchRequest);
    // 検索テーブルオプション
    const request = MenuSearch.getSearchRequest;
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * mountedライフサイクルフック（UIDataTableをソート可能にする）
   */
  mounted() {
    this.$nextTick(() => {
      const _self = this;

      const mainTable = document.querySelector(
        ".v-data-table tbody"
      ) as HTMLElement;
      Sortable.create(mainTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.tableItems.splice(oldIndex as number, 1)[0];
          _self.tableItems.splice(newIndex as number, 0, rowSelected);
          _self.sort().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });
    });
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MenuSearch.clearResponse();
    await MenuModify.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * Webプレビュー
   */
  webPreview() {
    if (MenuSearch.getPreviewUrl) {
      WindowOpen.webPreview(MenuSearch.getPreviewWebUrl);
    }
  }

  /**
   * プレビュー
   */
  preview() {
    if (MenuSearch.getPreviewUrl) {
      WindowOpen.preview(MenuSearch.getPreviewUrl);
    }
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: SearchItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: SearchItem) {
    await this.$router.push({
      name: "menu-management-category-menu-list-edit",
      params: { shopId: this.shopId, categoryId: this.id, id: item.id },
      query: { shopName: this.shopName, category_Title: this.category_Title }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: SearchItem) {
    this.showDialog = true;
    this.deletingMenuId = item.id;
    this.deletingMenuTitle = item.title;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (!this.deletingMenuId) {
      return;
    }

    this.isLoading = true;
    await MenuModify.deleteOne({
      id: Number(this.deletingMenuId)
    } as DeleteRequest);
    this.isLoading = false;
    if (MenuModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "メニューを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: MenuModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    await MenuSearch.search({
      shopId: this.shopId,
      categoryId: Number(this.id)
    } as SearchRequest);
    if (!MenuSearch.isSuccess) {
      await Flash.setErrorNow({
        message: MenuSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sort() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await MenuModify.sort(this.createSortRequest());
    if (!MenuModify.isSuccess) {
      await Flash.setErrorNow({
        message: MenuModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToNew() {
    this.$router
      .push({
        name: "menu-management-category-menu-list-new",
        params: { shopId: this.shopId, categoryId: this.id },
        query: { shopName: this.shopName, category_Title: this.category_Title }
      })
      .then();
  }

  backToCategoryList() {
    this.$router
      .push({
        name: "menu-management-category",
        params: { shopId: this.shopId },
        query: { shopName: this.shopName, category_Title: this.category_Title }
      })
      .then();
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSortRequest() {
    const request = { categoryId: Number(this.id) } as SortRequest;
    request.ids = this.tableItems.map(i => Number(i.id));
    return request;
  }
}
